<template>
    <div class="message-project-mini-details-container" v-if="selectedUserMessageRoom">
        <div class="header-container">
            <p class="title">Details</p>
        </div>
        <div class="project-content-container">
           <el-scrollbar v-if="hasDetails">
                <ProjectDetailMembersBoxComponent v-if="roomMembers.length" :members="roomMembers" :isGroupRoom="isGroupRoom"/>
                <ProjectDetailMediaBoxComponent v-if="imgFiles.length" :attachments="imgFiles"/>
                <!-- <ProjectDetailTasksBoxComponent/> -->
                <ProjectDetailDocumentsBoxComponent v-if="documentFiles.length" :documents="documentFiles"/>
            </el-scrollbar>
            <div class="no-content" v-else>
              <NoContentComponent tag="Nothing here"/>
            </div>
        </div>
    </div>
</template>
<script>
// import ProjectDetailTasksBoxComponent from './ProjectDetailTasksBoxComponent.vue';
import { mapActions, mapGetters } from 'vuex';

import NoContentComponent from '@/core/components/messages/NoContentComponent.vue';
import { MESSAGES_STORE } from '@/store/modules/messages';
import { USERS_STORE } from '@/store/modules/users';

import ProjectDetailDocumentsBoxComponent from './ProjectDetailDocumentsBoxComponent.vue';
import ProjectDetailMediaBoxComponent from './ProjectDetailMediaBoxComponent.vue';
import ProjectDetailMembersBoxComponent from './ProjectDetailMembersBoxComponent.vue';

export default {
  name: 'message-project-mini-details-component',
  components: {
    // HeaderCta,
    ProjectDetailDocumentsBoxComponent,
    ProjectDetailMediaBoxComponent,
    ProjectDetailMembersBoxComponent,
    // ProjectDetailTasksBoxComponent,
    NoContentComponent
  },
  props: ['acceptedProjectMembers', 'selectedUserMessageRoom'],

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    roomAttachments() {
      return this.selectedUserMessageRoom?.userMessageAttachments;
    },

    isGroupRoom() {
      return this.selectedUserMessageRoom?.isGroup;
    },
  },

  data() {
    return {
      hasDetails: false,
      documentFiles: [],
      imgFiles: [],

      // groupRoomMembers: [],
      // individualChatMembers: [],
      roomMembers: [],
    };
  },

  watch: {
    selectedUserMessageRoom: {
      immediate: true,
      deep: true,
      handler() {
        this.initialize();
        this.checkIfHasDetails();
      }
    },
  },

  created() {
  },
  mounted() {
  },

  methods: {
    ...mapActions(MESSAGES_STORE, ['getUserMessagesByGroup']),

    async initialize() {
      const { selectedUserMessageRoom, user, isGroupRoom } = this;

      this.roomMembers = [];

      this.initializeAttachments();

      if (selectedUserMessageRoom?.id && user && isGroupRoom) {
        await this.getUserMessagesByGroup({
          userId: user.id,
          userMessageRoomId: selectedUserMessageRoom.id
        })
          .then((response) => {
            this.roomMembers = this.normalizeGroupList(response);
            this.checkIfHasDetails();
          })
          .catch(() => {})
          .finally(() => {
          });
      }

      if (selectedUserMessageRoom && user && !isGroupRoom) {
        const members = [
          selectedUserMessageRoom?.fromUser,
          selectedUserMessageRoom?.toUser
        ];
        this.roomMembers = members;
      }
    },

    checkFile(obj) {
      if (obj.attachment.indexOf('.pdf') > -1 || obj.attachment.indexOf('.doc') > -1) {
        this.documentFiles.push(obj);
      } else {
        this.imgFiles.push(obj);
      }
    },

    initializeAttachments() {
      this.documentFiles = [];
      this.imgFiles = [];

      if (this.roomAttachments) {
        for (let i = 0; i < this.roomAttachments.length; i++) {
          this.checkFile(this.roomAttachments[i]);
        }
      } else {
        this.documentFiles = [];
        this.imgFiles = [];
      }
    },

    checkIfHasDetails() {
      if (this.roomMembers.length <= 0 && this.roomAttachments <= 0) {
        this.hasDetails = false;
      } else {
        this.hasDetails = true;
      }
    },

    normalizeGroupList(list) {
      const newList = [];

      for (let i = 0; i < list.length; i++) {
        const item = list[i];

        if (!newList.find((user) => user.id === item.fromUser.id)) {
          newList.push(item.fromUser);
        }

        if (!newList.find((user) => user.id === item.toUser.id)) {
          newList.push(item.toUser);
        }
      }

      return newList;
    }
  },
};
</script>
<style lang="scss" scoped>
.message-project-mini-details-container * p {
    font-family: 'Mulish';
    margin: 0;
}
.message-project-mini-details-container {
    position: relative;
}
.header-container {
  position:sticky;
  // width:277px;
  z-index: 10;
  // margin-top: -5px;
  // right: 30px;
  display:flex;
  flex-direction: column;
  gap:4rem;
  background-color: #F1F6FB;
  top: 0;

  .title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-align: left;
    color: #0C0F4A;
    padding-bottom: 1rem;
  }
}
.project-content-container {
    position: relative;
    // top:160px;

    .no-content {
        height: 100%;
        position: relative;
        top:100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.el-scrollbar {
    padding-bottom: 2rem;

    :deep(.el-scrollbar__view) {
        display:flex;
        flex-direction:column;
        gap:2rem;
    }
}
</style>
