import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
// import Expand from '@/core/components/ui/Expand.vue';
// import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import appFilters from '@/filters';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
import { USERS_STORE } from '../../../store/modules/users';
const READ = 'read';
export default defineComponent({
    name: 'contact-item-component',
    components: {
    // UserThumbnail,
    // Expand
    },
    props: ['item', 'selectedUserMessageRoom', 'isProjectChat'],
    data() {
        return {
            unreadCount: 0,
            ProjectJobStatusEnum,
            projectAttachmentThumbnail: null,
            userThumbnail: null,
            groupThumbnail: null,
            groupAvatarSrc: ''
        };
    },
    emits: ['select-user-message-room'],
    async created() {
        await this.loadProjectThumbnail();
        await this.loadUserThumbnail();
        await this.loadGroupThumbnail();
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
        getUnreadMessages() {
            if (this.item.userMessages?.length) {
                const unreadMessages = this.item.userMessages.filter((m) => m.state === 'unread'
                    && (m.fromUser.id !== this.authenticatedUser?.userId));
                return unreadMessages.length;
            }
            return 0;
        },
        member() {
            let member = this.item.fromUser && this.item.fromUser.id !== this.authenticatedUser?.userId
                ? this.item.fromUser : this.item.toUser;
            if (!member) {
                member = this.item?.project?.projectCreator;
            }
            return member;
        },
        memberIsOnline() {
            if (this.item?.members?.length) {
                // eslint-disable-next-line radix
                return this.item.members.find((m) => parseInt(m.id) === this.member.id);
                // return this.item.id === this.member.id;
            }
            return false;
        },
        getLastContent() {
            const { userMessages, userMessageAttachments } = this.item;
            const attachmentMessagesFiltered = userMessageAttachments.filter((message) => {
                const { user: loggedInUser } = this;
                return message.createdByUserId !== loggedInUser.id;
            });
            const messagesFiltered = userMessages.filter((message) => {
                return message;
            });
            const messages = messagesFiltered.concat(attachmentMessagesFiltered);
            // eslint-disable-next-line no-nested-ternary
            messages.sort((a, b) => (a.dateCreated > b.dateCreated ? 1 : b.dateCreated > a.dateCreated ? -1 : 0));
            const lastMessage = messages.length ? messages[messages.length - 1] : '';
            // const lastMessageContent = lastMessage.attachment ? 'Attachment Sent' : lastMessage.content;
            return lastMessage;
        },
        isRead() {
            return this.getLastContent.state === READ;
        },
        isYourMessage() {
            if (this.user?.id === this.getLastContent.fromUser?.id) {
                return true;
            }
            return false;
        },
        getUnreadCount() {
            const { userMessages, userMessageAttachments } = this.item;
            const attachmentMessagesFiltered = userMessageAttachments.filter((message) => {
                const { user: loggedInUser } = this;
                return message.createdByUserId !== loggedInUser.id;
            });
            const messagesFiltered = userMessages.filter((message) => {
                const { user: loggedInUser } = this;
                return message.fromUser.id !== loggedInUser.id;
            });
            const messages = messagesFiltered.concat(attachmentMessagesFiltered);
            const unreadList = messages.filter((message) => message === 'unread');
            return unreadList.length;
        },
        getContactThumbnail() {
            const { projectAttachmentThumbnail, userThumbnail, groupThumbnail, item } = this;
            let thumbnail = userThumbnail || groupThumbnail;
            if (item.groupName) {
                thumbnail = groupThumbnail;
            }
            else {
                thumbnail = projectAttachmentThumbnail;
            }
            if (item.isDirect) {
                thumbnail = userThumbnail || null;
            }
            return thumbnail;
        },
    },
    watch: {
        'item.userMessages': {
            handler(values) {
                const messages = values.filter((message) => {
                    const { user: loggedInUser } = this;
                    return message.fromUser.id !== loggedInUser.id;
                });
                // eslint-disable-next-line no-nested-ternary
                messages.sort((a, b) => (a.dateCreated > b.dateCreated ? 1 : b.dateCreated > a.dateCreated ? -1 : 0));
                const res = messages.filter((val) => val.state === 'unread');
                this.unreadCount = res.length;
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        async loadProjectThumbnail() {
            const { project } = this.item;
            if (project?.projectAttachments?.length) {
                const [firstProjectAttachment] = project?.projectAttachments;
                if (firstProjectAttachment) {
                    this.projectAttachmentThumbnail = await appFilters.formatToImageSourceUrl(firstProjectAttachment.attachment);
                }
            }
        },
        async loadUserThumbnail() {
            const { item } = this;
            const { fromUser, toUser, isDirect } = item;
            const { userPublicProfile: userPublicProfileFrom } = fromUser;
            const { userPublicProfile: userPublicProfileTo } = toUser;
            let imgSrc = null;
            if (isDirect) {
                imgSrc = userPublicProfileTo?.profileImage;
            }
            else {
                imgSrc = userPublicProfileFrom?.profileImage;
            }
            if (imgSrc) {
                this.userThumbnail = await appFilters.formatToImageSourceUrl(imgSrc);
            }
        },
        async loadGroupThumbnail() {
            const { item } = this;
            const { groupAvatar } = item;
            if (groupAvatar) {
                this.groupThumbnail = await appFilters.formatToImageSourceUrl(groupAvatar);
            }
        },
        selectUserMessageRoom(userMessageRoom) {
            this.$emit('select-user-message-room', userMessageRoom);
        },
        getContactLabel(contact) {
            const { user, isProjectChat, authenticatedUser } = this;
            const { userId } = authenticatedUser;
            const { fromUser, isDirect, toUser, isGroup, project, groupName } = contact;
            let value = `${fromUser.firstName} ${fromUser.lastName}`;
            if (user.id === fromUser.id) {
                value = `${toUser.firstName} ${toUser.lastName}`;
            }
            if (isGroup) {
                value = `Group ${value}`;
            }
            if (!isProjectChat) {
                value = project.name;
            }
            if (groupName) {
                value = groupName;
            }
            if (isDirect) {
                value = userId !== toUser.id
                    ? `${toUser.firstName} ${toUser.lastName}` : `${fromUser.firstName} ${fromUser.lastName}`;
            }
            return value;
        },
    }
});
