<template>
    <BoxSlotComponent title="Media" :count="srcList.length">
      <template #action>
        <ActionItemComponent
          v-if="srcList.length > 7"
          :hideIcon="true"
          :label="isShowAll ? 'See less' : 'See all'"
          fontColor="#FFA500"
          fontSize="14px"
          @call-to-action="onViewAllAttachments"/>
      </template>
      <template #>
        <el-card>
          <div class="card-content" v-if="isMessageAttachmentLoaded">
            <div class="file" v-for="(file, index) in toDisplayAttachments" :key="index">
              <el-image
                class="attachment-image"
                fit="cover"
                :src="file"
                :preview-src-list="srcList"
                :append-to-body="true"
              >
                <template v-slot:error>
                <div class="image-slot">
                  <el-icon><Picture /></el-icon>
                </div>
                </template>
              </el-image>
            </div>
            <div class="not-shown-count flex-row ai-c jc-c" v-if="notShownAttachments.length && !isShowAll">
              <p class="default-text">+{{notShownAttachments.length}}</p>
            </div>
          </div>
          <MessageDetailsMediaSkeleton v-else/>
        </el-card>
      </template>
    </BoxSlotComponent>
</template>
<script>
import { Picture } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';
import MessageDetailsMediaSkeleton from '@/core/components/messages/placeholder/MessageDetailsMediaSkeleton.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_MESSAGES_CACHE } from '@/store/modules/attachment-cache/constants';

export default defineComponent({
  name: 'project-detail-media-box-component',
  components: {
    BoxSlotComponent,
    Picture,
    ActionItemComponent,
    MessageDetailsMediaSkeleton
  },
  props: ['attachments'],

  data() {
    return {
      srcList: [],
      showNumberOfAttachments: 7,
      shownAttachments: [],
      notShownAttachments: [],

      isMessageAttachmentLoaded: true,
      attachmentUrls: [],

      toDisplayAttachments: [],
      isShowAll: false,
    };
  },

  watch: {
    attachments: {
      immediate: true,
      // deep: true,
      handler() {
        this.loadAttachments();
      }
    }
  },

  computed: {
    list() {
      return this.attachments;
    }
  },

  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),

    loadAttachments() {
      const items = this.attachments;

      this.srcList = [];
      this.shownAttachments = [];
      this.notShownAttachments = [];
      this.toDisplayAttachments = [];

      this.isMessageAttachmentLoaded = false;

      this.getAttachments({
        name: USER_MESSAGES_CACHE,
        attachments: items,
        includeUrl: true
      })
        .then((values) => {
          const newValues = values.filter(Boolean);
          if (newValues.length) {
            this.attachmentUrls = newValues;

            if (this.attachmentUrls?.length) {
              this.attachmentUrls.forEach((attachmentUrl) => {
                const findListItem = this.list.find((listItem) => listItem?.attachment && listItem?.attachment === attachmentUrl?.attachment);

                if (findListItem) {
                  const indexOfFoundListItem = this.list.indexOf(findListItem);

                  findListItem.src = attachmentUrl.src;
                  findListItem.url = attachmentUrl.url;
                  this.list[indexOfFoundListItem] = findListItem;

                  this.srcList.push(attachmentUrl.url);
                }
              });
              if (this.srcList.length) {
                const getShownAttachments = this.srcList.reduce((mappedArray, item, index) => {
                  if (index < this.showNumberOfAttachments) {
                    mappedArray.push(item);
                  }
                  return mappedArray;
                }, []);
                const getNotShownAttachments = this.srcList.reduce((mappedArray, item, index) => {
                  if (index >= this.showNumberOfAttachments) {
                    mappedArray.push(item);
                  }
                  return mappedArray;
                }, []);

                this.shownAttachments = getShownAttachments;
                this.notShownAttachments = getNotShownAttachments;
                this.toDisplayAttachments = getShownAttachments;
              }
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isMessageAttachmentLoaded = true;
        });
    },
    onViewAllAttachments() {
      if (this.toDisplayAttachments.length === this.shownAttachments.length) {
        this.toDisplayAttachments = this.srcList;
        this.isShowAll = true;
      } else {
        this.toDisplayAttachments = this.shownAttachments;
        this.isShowAll = false;
      }
    }
  }

});
</script>
<style lang="scss" scoped>
.el-card {
    background: transparent;
    outline:none;
    border:none;
    box-shadow: none;
    :deep(.el-card__body) {
        padding:0;
    }
    .card-content {
        display:grid;
        grid-template-columns: repeat(auto-fill, minmax(50px,1fr));
        gap:1rem;

        .file {
            border-radius: 8px;
            height: 50px;
            .el-image {
                height:100%;
                border-radius: 8px;
                width: 100%;
            }
        }
        .not-shown-count {
          background: rgba(12, 15, 74, 0.1);
          border-radius: 8px;
          box-sizing: border-box;
          width: 50px;
          height: 50px;

          p {
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.25px;
          }
        }
    }
}
</style>
