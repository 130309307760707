import { CloseBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'edit-property-details-modal',
    emits: ['on-confirm', 'on-cancel'],
    components: {
        BaseModal,
        CloseBold
    },
    data() {
        return {
            loading: false,
            isButtonReady: false,
            microTaskForm: {
                taskTitle: '',
                taskDate: '',
                taskProject: '',
                description: '',
                assignTo: ''
            },
        };
    },
    watch: {
    // 'propertyDetailsForm.beds': {
    //   immediate: true,
    //   deep: true,
    //   handler(newValue) {
    //     (this as any).formChecker(newValue, 'beds');
    //   }
    // },
    },
    created() {
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        },
    }
});
