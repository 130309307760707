<template>
    <div class="message-dashboard-list-header">
        <div class="column-one">
            <el-icon :size="20" color="rgba(12, 15, 74, 0.5)" @click="backToContacts"><ArrowLeftBold/></el-icon>
            <div class="message-to-profile-container">
                <el-image class="message-to-profile-container__thumbnail" v-if="profileImage" fit="cover" :src="profileImage">
                  <template v-slot:placeholder>
                    <div class="image-slot">
                      <div class="loader-img" :style="{ backgroundImage: 'url(' +  require('@/assets/images/project-placeholder.png') + ')' }">
                        <div class="loader"></div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:error>
                    <div class="image-slot">
                      <el-image
                        class="profile-image"
                        :src="groupAvatarSrc"
                        v-if="groupAvatarSrc"
                        :fit="'cover'">
                      </el-image>
                    </div>
                  </template>
                </el-image>
                <img v-else-if="!profileImage && !projectRefId" class="message-to-profile-container__image-placeholder"  src="@/assets/images/project-placeholder.png"/>
                <img v-else-if="!profileImage && projectRefId" class="message-to-profile-container__image-placeholder"  src="@/assets/images/user-placeholder.jpg"/>
                <p class="to-name">{{ chatRoomLabel }} [PROJECT .ID: {{ projectRefId }}]</p>
            </div>
            <!-- <div class="message-actions-container">
                <el-icon class="action-icon" :size="15" color="#0C0F4A"><PhoneFilled/></el-icon>
                <inline-svg class="action-icon" :src="require(`@/assets/icons/messages-add-video-icon.svg`)"></inline-svg>
                <el-icon class="action-icon" :size="15" color="#0C0F4A"><PictureFilled/></el-icon>
                <inline-svg class="action-icon" :src="require(`@/assets/icons/messages-files-icon.svg`)"></inline-svg>
            </div> -->
        </div>
        <div class="column-two">
            <!-- <el-icon class="action-icon" :size="17" color="#fff"><Plus/></el-icon> -->
            <!-- <div class="thumbnail-container">
                <div class="thumbnail">
                    <span v-for="(member, index) in members" :key="index">
                        <UserThumbnail
                            class="profile"
                            borderRadius="50px"
                            width="30px"
                            height="30px"
                            :key="require(`@/assets/images/box-empty.jpeg`)"
                            :source="require(`@/assets/images/box-empty.jpeg`)"
                        />
                    </span>
                </div>
                <p class="plus-count-members">+{{ members?.length }}</p>
            </div> -->
        </div>
        <div class="column-three">
            <span><el-icon :size="20" color="#0C0F4A"><Expand/></el-icon></span>
        </div>
    </div>
</template>
<script>
import {
  ArrowLeftBold,
  Expand,
  //   PhoneFilled,
  //   PictureFilled,
//   Plus,
} from '@element-plus/icons';
import { mapGetters } from 'vuex';

import appFilters from '@/filters';

import { USERS_STORE } from '../../../store/modules/users';

export default {
  components: {
    // PhoneFilled,
    // PictureFilled,
    // Plus,
    Expand,
    ArrowLeftBold
  },
  props: ['selectedUserMessageRoom', 'isProjectChat', 'members'],
  emits: ['set-view-contact'],
  data() {
    return {
      projectAttachmentThumbnail: null,
      userThumbnail: null,
      groupAvatarSrc: ''
    };
  },
  watch: {
    'selectedUserMessageRoom.project': {
      immediate: true,
      deep: true,
      async handler(newval) {
        if (newval) {
          await this.loadProjectThumbnail();
          await this.loadUserThumbnail();
        }
      }
    },
  },
  async mounted() {
    await this.loadProjectThumbnail();
    await this.loadUserThumbnail();
  },
  methods: {
    async loadProjectThumbnail() {
      this.projectAttachmentThumbnail = null;
      const project = this.selectedUserMessageRoom;

      if (project?.projectAttachments?.length) {
        const [firstProjectAttachment] = project?.projectAttachments;

        if (firstProjectAttachment) {
          this.projectAttachmentThumbnail = await appFilters.formatToImageSourceUrl(firstProjectAttachment.attachment);
        }
      }
    },
    async loadUserThumbnail() {
      this.userThumbnail = null;
      let userProfile = null;
      if (this.user?.id !== this.selectedUserMessageRoom?.toUser?.id) {
        userProfile = this.selectedUserMessageRoom?.toUser;
      } else {
        userProfile = this.selectedUserMessageRoom?.fromUser;
      }

      if (userProfile && userProfile?.userPublicProfile?.profileImage) {
        const { profileImage } = userProfile.userPublicProfile;

        if (profileImage) {
          this.userThumbnail = await appFilters.formatToImageSourceUrl(profileImage);
        }
      }
    },
  },
  computed: {
    ...mapGetters(['isProjectOwner', 'isTradesperson']),
    ...mapGetters(USERS_STORE, ['user']),

    projectRefId() {
      return this.$route.params.projectRefId;
    },

    chatRoomLabel() {
      const { selectedUserMessageRoom } = this;
      const { projectRefId } = this.$route.params;
      let label = '';

      if (selectedUserMessageRoom) {
        const {
          isGroup, isDirect, project, groupName, toUser, fromUser
        } = selectedUserMessageRoom;
        label = `${project?.name}`;

        if (projectRefId) {
          if (this.isTradesperson) {
            label = `${toUser.firstName} ${toUser.lastName}`;
          }

          if (this.isProjectOwner) {
            label = `${fromUser.firstName} ${fromUser.lastName}`;
          }
        }

        if (!projectRefId) {
          label = project?.name;
        }

        if (isGroup) {
          label = groupName;
        }

        if (isDirect) {
          label = `${toUser.firstName} ${toUser.lastName}`;
        }
      }

      return label;
    },
    profileImage() {
      const { selectedUserMessageRoom, projectAttachmentThumbnail, userThumbnail } = this;

      const { projectRefId } = this.$route.params;
      let image = '';

      if (selectedUserMessageRoom) {
        const {
          groupAvatar, isGroup
        } = selectedUserMessageRoom;

        if (projectRefId) {
          image = userThumbnail;
        }

        if (!projectRefId) {
          image = projectAttachmentThumbnail;
        }

        if (projectRefId && isGroup) {
          image = groupAvatar;
        }
      }

      return image;
    },
  },

  setup(props, { emit }) {
    const backToContacts = () => {
      emit('set-view-contact');
    };

    return {
      backToContacts
    };
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.message-dashboard-list-header * p {
    margin: 0;
}
.message-dashboard-list-header {
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;

    .column-one {
        display:flex;
        align-items: center;
        gap:2rem;

        .el-icon {
            display: none;
        }

        .message-to-profile-container {
            display:flex;
            gap: 1rem;
            align-items: center;

            .to-name {
                font-size: 20px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                line-height: 1.2em;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
          &__thumbnail {
            width: 30px;
            min-width: 30px;
            max-width: 30px;
            height: 30px;
            border-radius: 100%;
          }
          &__image-placeholder {
            width: 30px;
            min-width: 30px;
            max-width: 30px;
            height: 30px;
            border-radius: 100%;
          }
        }

        .message-actions-container {
            display:flex;
            gap: 1rem;

            .action-icon {
                background: rgba(12, 15, 74, 0.05);
                border-radius: 8px;
                padding:.5rem;
                width: 30px;
                height:30px;
                box-sizing: border-box;
            }
        }
    }

    .column-two {
        display:flex;
        align-items:center;
        gap: 1rem;

        .action-icon {
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            border-radius: 50px;
            background-color: #FFA500;
            // padding: .5rem;
            display:flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        .thumbnail-container {
            display:flex;
            align-items:center;
            gap: .5rem;

            .thumbnail {
                display:flex;
                align-items:center;
               .profile {
               }
            }

            .plus-count-members {
                width: 30px;
                height: 30px;
                box-sizing: border-box;
                border-radius: 50px;
                background-color: rgba(12, 15, 74, 0.05);
                display:flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: center;
                color: #0C0F4A;
            }
        }
    }
    .column-three {
        display:none;
    }
}

@media screen and (max-width: 1573px) {
    .message-dashboard-list-header {
        .column-one {
            .message-actions-container {
                display:none;
            }
        }
        .column-two {
            display: none;
        }
        .column-three {
            display:inline-block;
            display:flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: 8px;
            background: rgba(12, 15, 74, 0.1);
        }
    }
}

@media screen and (max-width: 1024px) {
    .message-dashboard-list-header {
        .column-one {
            gap: 1rem;
            .el-icon {
                display: inline-block;
            }
        }
    }
}

@include media-md-max-width() {
    .message-dashboard-list-header {
        margin-bottom: 1rem;
    }
}

@include media-xs-max-width() {
  .message-dashboard-list-header {
    .column-one {
      .message-to-profile-container {
        .to-name {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
