import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import NoContentComponent from '@/core/components/messages/NoContentComponent.vue';
import MessageSidebarSkeleton from '@/core/components/messages/placeholder/MessageSidebarSkeleton.vue';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
import MessageContactListComponent from './MessageContactListComponent.vue';
import MessageListFilterComponent from './MessageListFilterComponent.vue';
import NewGroupChatFormComponent from './NewGroupChatFormComponent.vue';
import NewSingleChatFormComponent from './NewSingleChatFormComponent.vue';
export default defineComponent({
    name: 'message-contacts-component',
    components: {
        MessageContactListComponent,
        MessageListFilterComponent,
        MessageSidebarSkeleton,
        NoContentComponent,
        NewSingleChatFormComponent,
        NewGroupChatFormComponent
    },
    data() {
        return {
            isCreatingNewSingleChat: false,
            isCreatingNewGroupChat: false,
            totalUnread: 0,
            /* eslint-disable global-require */
            popOverData: [
                {
                    icon: `${require('@/assets/icons/single-chat-icon.svg')}`,
                    name: 'New chat',
                    event: 'toggleSingleChatForm',
                },
                {
                    icon: `${require('@/assets/icons/group-chat-icon.svg')}`,
                    name: 'New group chat',
                    event: 'toggleGroupChatForm',
                },
            ],
            /* eslint-disable global-require */
            isPopOverOpen: false,
        };
    },
    // props: ['messageRooms', 'selectedMessageRoom', 'loadingMessageRooms', 'totalUnreadMessages'],
    // emits: ['select-message', 'filter', 'set-view-contact'],
    props: ['userMessageRooms', 'selectedUserMessageRoom', 'loadingMessageRooms', 'acceptedProjectMembers'],
    emits: ['filter-user-message-rooms', 'select-user-message-room', 'set-view-contact', 'on-save-user-room', 'on-save-group-user-room'],
    computed: {
        ...mapGetters(USERS_STORE, ['user', 'isHosted']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        isProjectChat() {
            const { projectRefId } = this.$route.params;
            let value = false;
            if (projectRefId) {
                value = !value;
            }
            return value;
        }
    },
    // watch: {
    //   messageRooms: {
    //     handler(values) {
    //       const totalUnread = [];
    //       values.forEach((messageRoom) => {
    //         const { userMessages, userMessageAttachments } = messageRoom;
    //         const attachmentMessagesFiltered = userMessageAttachments.filter((message) => {
    //           const { user: loggedInUser } = this;
    //           return message.createdByUserId !== loggedInUser.id;
    //         });
    //         const messagesFiltered = userMessages.filter((message) => {
    //           const { user: loggedInUser } = this;
    //           return message.fromUser.id !== loggedInUser.id;
    //         });
    //         const messages = messagesFiltered.concat(attachmentMessagesFiltered);
    //         messages.forEach((message) => {
    //           if (message.state === 'unread') {
    //             totalUnread.push([]);
    //           }
    //         });
    //       });
    //       this.totalUnread = totalUnread.length;
    //     },
    //     immediate: true,
    //     deep: true
    //   }
    // },
    created() {
    },
    methods: {
        ...mapActions(USERS_STORE, ['getUser']),
        togglePopOver() {
            this.isPopOverOpen = !this.isPopOverOpen;
        },
        onChangeFilterUserMessageRooms(searchStr) {
            this.$emit('filter-user-message-rooms', searchStr);
        },
        selectUserMessageRoom(userMessageRoom) {
            this.$emit('select-user-message-room', userMessageRoom);
            // (this as any).$emit('view-contacts');
            this.$emit('set-view-contact');
        },
        // selectMessage(roomId) {
        //   this.$emit('select-message', roomId);
        //   this.$emit('set-view-contact');
        // },
        // filter(keyword) {
        //   this.$emit('filter', keyword);
        // },
        onSaveUserRoom(value) {
            this.toggleSingleChatForm();
            this.$emit('on-save-user-room', value);
        },
        onSaveGroupUserRoom(values) {
            this.toggleGroupChatForm();
            this.$emit('on-save-group-user-room', values);
        },
        handleFunctionCall(event) {
            if (event && event === 'toggleSingleChatForm') {
                this.toggleSingleChatForm();
            }
            if (event && event === 'toggleGroupChatForm') {
                this.toggleGroupChatForm();
            }
        },
        toggleSingleChatForm() {
            this.isCreatingNewSingleChat = !this.isCreatingNewSingleChat;
        },
        toggleGroupChatForm() {
            this.isCreatingNewGroupChat = !this.isCreatingNewGroupChat;
        }
    }
});
