<template>
  <div class="chat-list-container" :class="{'project-chat': routeName === 'chat'}">
    <div class="no-content-container" v-if="getUserMessageList.length <= 0">
      <NoContentComponent/>
    </div>
    <div v-else class="chat-list-wrapper"  style="overflow: auto" v-infinite-scroll="setUnreadMessageList" :ref="`message-items-wrapper-${selectedMessageRoom.id}`" >
      <div class="message-file-upload-progress padding-16" v-if="currentUploadedFile && currentUploadedFile?.event?.percent">
        <h4 class="margin-vertical-0">Uploading file {{ currentUploadedFile?.file?.name }}</h4>
        <el-progress indeterminate :percentage="100" status="warning"></el-progress>
      </div>
      <transition-group name="message-list" class="chat-messages" tag="div" >
        <div v-for="item in getUserMessageList" :key="item.id" :class="[item.fromUser?.id === user.id || item?.createdByUserId === user.id ? 'sender' : 'receiver', 'message']">
          <!-- <div :class="[item.fromUser?.id === user.id || item?.createdByUserId === user.id && 'content-wrapper-sender', 'content-wrapper']"> -->
          <div class="content-wrapper" :class="{'content-wrapper-sender': item.fromUser?.id === user.id || item?.createdByUserId === user.id}">
            <UserThumbnail borderRadius="8px" width="40px" height="40px"
              :key="getUserThumbnail(item)"
              :source="getUserThumbnail(item)"></UserThumbnail>
            <div class="content-right">
              <div :class="[item.fromUser?.id === user.id ? 'name-sender' : 'name-receiver']" v-if="item.fromUser">
                <a @click="routeToProfile(item?.fromUser.id)">{{ item?.fromUser.firstName }} {{item?.fromUser.lastName}}</a></div>
              <div class="content-right__content" :class="{'is-attachment': item?.attachment}">
                <span v-if="item?.content" v-html="item?.content"></span>

                <template v-if="item?.src && isImageAttachment(item)">
                  <el-image style="width: 100px; height: 100px; border-radius: 10px;" fit="cover" v-if="item?.src" :src="item.src" class="margin-right-8"
                    :preview-src-list="[item.src]"
                    :append-to-body="true">
                    <el-icon><Picture /></el-icon>
                  </el-image>
                </template>
                <template v-else-if="isPdfFile(item.url)">
                  <div class="content-right__document-wrapper">
                    <a :href="item.url" title="View Pdf" target="_blank" class="link">
                      <img class="pdf-icon" src="@/assets/icons/pdf-document-icon.svg"/>
                    </a>
                  </div>
                </template>
                <template v-else-if="item.url && !isImageAttachment(item)">
                  <div class="content-right__document-wrapper">
                    <a :href="item.url" title="View File" target="_blank" class="link">
                      <el-icon><Document /></el-icon>
                    </a>
                  </div>
                </template>
                <small v-else>
                  <el-icon v-if="!item.content && !isMessageAttachmentLoaded" class="is-loading"><Loading /></el-icon>
                </small>

                <span class="sent-time flex-row ai-c jc-c" style="gap: .2rem;">{{ timeSent(item.dateCreated) }}<el-icon><Check /></el-icon></span>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <div class="chat-input-container" v-if="selectedMessageRoom">
      <div class="input-wrapper">
        <div class="column-one">
          <div class="flex-row ai-fs js-fs" style="width: 100%;">
            <UserThumbnail v-if="user" borderRadius="50px" width="25px" height="25px" style="display:flex;justify-content:center;align-items:center;" :key="userThumbnail" :source="userThumbnail"> </UserThumbnail>
            <el-input class="input-chat" type="textarea" :rows="8" :disabled="inputDisable" placeholder="Your message..." v-model="inputMessage" clearable autosize @keyup.enter="saveNewMessage" @keyup="triggerIsTypingEvent"></el-input>
          </div>
          <!-- <el-popover placement="top" width="auto" trigger="click" popper-class="popper">
              <div class="options flex-column">
                  <div class="options__option flex-row ai-fs" v-for="(item, index) in popOverData" :key="index" @click="handleFunctionCall(item.event)">
                      <inline-svg class="download-icon" width="20" height="20" :src="item.icon"/>
                      <p class="options__label">{{item.name}}</p>
                  </div>
              </div>
              <template #reference>
                  <div class="plus-cta flex-row ai-c jc-c">
                    <el-icon :size="12" color="#fff"><Plus /></el-icon>
                  </div>
              </template>
          </el-popover>
          <el-divider direction="vertical" />
          <inline-svg class="cta-icon" :src="require(`@/assets/icons/chat-mic-icon.svg`)"></inline-svg>
          <inline-svg class="cta-icon" :src="require(`@/assets/icons/chat-vid-icon.svg`)"></inline-svg> -->
          <!-- <el-divider direction="vertical" /> -->
          <!-- <inline-svg class="cta-icon" :src="require(`@/assets/icons/chat-emoji-icon.svg`)"></inline-svg> -->
          <!-- <inline-svg class="cta-icon" :src="require(`@/assets/icons/clip-icon.svg`)"></inline-svg> -->
          <MessageInputWidgetsComponent class="message-input-widgets"
            @submit-uploading-attachment="uploadingAttachmentOnProgress"
            @submit-uploaded-attachment="doSubmitUploadedAttachment"/>
        </div>
        <div class="column-two">
          <inline-svg class="cta-icon" @click="saveNewMessage" :src="require(`@/assets/icons/chat-orange-send-icon.svg`)"></inline-svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Check, Document, Loading, Picture
} from '@element-plus/icons';
import moment from 'moment';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';

import MessageInputWidgetsComponent from '@/core/components/messages/MessageInputWidgetsComponent.vue';
import NoContentComponent from '@/core/components/messages/NoContentComponent.vue';
// import Loader from '@/core/components/ui/Loader.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { ImageFileTypes } from '@/core/helpers/file.helper';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_MESSAGES_CACHE } from '@/store/modules/attachment-cache/constants';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { MESSAGES_STORE } from '@/store/modules/messages';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  name: 'chat-list-component',
  components: {
    NoContentComponent,
    // Plus,
    UserThumbnail,
    MessageInputWidgetsComponent,

    Check,
    Document,
    Loading,
    Picture,
  },
  props: ['selectedMessageRoom'],
  emits: ['save-message', 'set-unread-list', 'trigger-is-typing-event', 'submit-attachment'],
  setup() {
    const routeName = computed(() => {
      return useRouter().currentRoute.value.name;
    });

    return {
      routeName,
    };
  },
  data() {
    return {
      inputMessage: '',
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/messages-icon.svg')}`,
          name: 'Create task',
          event: 'openCreateTaskModal',
        },
        {
          icon: `${require('@/assets/icons/upload-icon.svg')}`,
          name: 'Uplaod file from computer',
          event: 'uploadFile',
        },
      ],
      /* eslint-disable global-require */

      currentUploadedFile: null,
      isMessageAttachmentLoaded: true,

      attachmentUrls: [],
    };
  },
  watch: {
    'selectedMessageRoom.userMessages': {
      immediate: true,
      deep: true,
      handler(newval) {
        if (newval) {
          // this.loadAttachments();
          this.scrollToBottom();
        }
      },
    },
    'selectedMessageRoom.userMessageAttachments': {
      immediate: true,
      deep: true,
      handler(newval) {
        if (newval) {
          this.loadAttachments();
        }
      }
    }
  },
  created() {
  },
  computed: {
    ...mapGetters(USERS_STORE, ['user', 'isHosted']),
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),

    getUserMessageList() {
      let userMessageList = this.selectedMessageRoom?.userMessages || [];

      if (!userMessageList.length) {
        return userMessageList;
      }

      if (this.selectedMessageRoom.userMessageAttachments.length) {
        userMessageList = [
          ...this.selectedMessageRoom.userMessages,
          ...this.selectedMessageRoom.userMessageAttachments];
      }
      this.scrollToBottom();

      // eslint-disable-next-line no-nested-ternary
      userMessageList.sort((a, b) => ((a.dateCreated > b.dateCreated) ? 1 : ((b.dateCreated > a.dateCreated) ? -1 : 0)));
      return userMessageList;
    },
    userThumbnail() {
      return this.user?.userPublicProfile?.profileImage;
    }
  },
  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, ['getAttachments']),
    ...mapActions(MESSAGES_STORE, ['setOpenCreateMicroTaskModal']),

    routeToProfile(userId) {
      this.$router.push(`/profile/${userId}/about`);
    },

    setUnreadMessageList() {
      this.$emit('set-unread-list');
    },
    scrollToBottom() {
      setTimeout(() => {
        const element = this.$refs[`message-items-wrapper-${this.selectedMessageRoom.id}`];
        if (element) {
          element.scrollTop = element?.scrollHeight;
        }
      }, 100);
    },
    saveNewMessage() {
      let destinationUserId = this.selectedMessageRoom.toUser.id !== this.user.id ? this.selectedMessageRoom.toUser.id : this.selectedMessageRoom.fromUser.id;

      if (!this.inputMessage.trim()) {
        return;
      }

      if (this.selectedMessageRoom.isGroup) {
        destinationUserId = this.selectedMessageRoom.fromUser.id;
      }

      const message = {
        messageId: `${new Date().getTime()}`,
        projectId: this.selectedMessageRoom.project.id,
        content: this.inputMessage,
        userMessageRoomId: this.selectedMessageRoom.id || this.selectedMessageRoom.id,
        fromUser: this.isHosted ? this.authenticatedUser.userId : this.user.id,
        toUser: destinationUserId,
        state: 'unread'
      };
      this.$emit('save-message', message);
      this.inputMessage = '';
      this.scrollToBottom();
    },

    getUserThumbnail(message) {
      if (message.fromUser?.id === this.user?.id || message?.createdByUserId === this.user?.id) {
        return this.user.userPublicProfile?.profileImage;
      }

      return this.user?.id === this.selectedMessageRoom.toUser?.id
        ? this.selectedMessageRoom.fromUser.userPublicProfile?.profileImage
        : this.selectedMessageRoom.toUser.userPublicProfile?.profileImage;
    },

    loadAttachments() {
      const { userMessageAttachments } = this.selectedMessageRoom;
      const attachments = userMessageAttachments;

      this.isMessageAttachmentLoaded = false;

      this.getAttachments({
        name: USER_MESSAGES_CACHE,
        attachments,
        includeUrl: true
      })
        .then((values) => {
          const newValues = values.filter(Boolean);
          if (newValues.length) {
            this.attachmentUrls = newValues;

            if (this.attachmentUrls?.length) {
              this.attachmentUrls.forEach((attachmentUrl) => {
                const findListItem = this.getUserMessageList.find((listItem) => listItem?.attachment && listItem?.attachment === attachmentUrl?.attachment);

                if (findListItem) {
                  const indexOfFoundListItem = this.getUserMessageList.indexOf(findListItem);

                  findListItem.src = attachmentUrl.src;
                  findListItem.url = attachmentUrl.url;

                  this.getUserMessageList[indexOfFoundListItem] = findListItem;
                }
              });
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isMessageAttachmentLoaded = true;
        });
    },

    isImageAttachment(file) {
      let { attachment: filename } = file;
      const { name } = file;

      if (!filename) { filename = name; }
      if (!filename) return false;

      const extension = filename.split('.')[filename.split('.').length - 1];

      return ImageFileTypes.includes(extension.toLowerCase());
    },

    isPdfFile(filename) {
      return filename && filename.indexOf('.pdf') > -1;
    },

    uploadingAttachmentOnProgress(event, file) {
      this.currentUploadedFile = {
        file,
        event,
      };
    },

    doSubmitUploadedAttachment(file) {
      this.$emit('submit-attachment', file, (response) => {
        if (response) {
          const fileName = this.currentUploadedFile?.file?.name;
          this.$notify.success({
            message: `You successfully uploaded "${fileName}".`
          });
          this.currentUploadedFile = null;
        }
      });
    },

    timeSent(dateCreated) {
      const date = new Date(dateCreated);
      return moment(date).format('HH:mm');
    },
    triggerIsTypingEvent() {
      this.$emit('trigger-is-typing-event', this.selectedMessageRoom);
    },
    handleFunctionCall(event) {
      if (event && event === 'openCreateTaskModal') {
        this.setOpenCreateMicroTaskModal(true);
      }
    },
  },
  // setup() {
  //   const count = ref(10);
  //   const loading = ref(false);
  //   const message = ref('');
  //   const noMore = computed(() => count.value >= 20);
  //   const hasMessage = ref(true);

  //   const load = () => {
  //     loading.value = true;
  //     setTimeout(() => {
  //       count.value += 2;
  //       loading.value = false;
  //     }, 2000);
  //   };
  //   return {
  //     count,
  //     hasMessage,
  //     load,
  //     loading,
  //     message,
  //     noMore
  //   };
  // }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.options {
  &__option {
    cursor: pointer;
    padding: .3rem;
    border-radius: 4px;
    &:hover {
        background: rgba(255, 165, 0, 0.1);
        .options__label {
            color: #FFA500;
        }
    }

    // &:last-child {
    //     position: relative;
    //     margin-top: 1rem;
    //     &::before {
    //         content: '';
    //         background: rgba(12, 15, 74, 0.2);
    //         position: absolute;
    //         top: -1rem;
    //         left:0;
    //         right:0;
    //         width: 100%;
    //         height: 1px;
    //     }
    // }
  }

  &__label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
    // white-space: nowrap;
  }
}
.chat-list-container {
  background: #fff;
  // height: calc(100vh - 160px);
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .chat-messages {
    display: flex;
    flex-direction: column;
    .content-wrapper {
      display: flex;
      gap: 8px;
      align-items: flex-start;
      max-width: 65%;
      overflow-wrap: break-word;
      .content-right {
        .name-sender a {
          color: #0c0f4a;
          text-align: right;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .name-receiver a {
          color: #0c0f4a;
          text-align: left;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        &__document-wrapper {
          width: 100px;
          height: 100px;
          .link {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .content-wrapper-sender {
      flex-direction: row-reverse;
    }
    .message {
      display: flex;
      align-items: center;
      margin: 20px;
      font-size: 14px;
      .content-right__content {
        padding: 7px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 5px;
        line-height: 25px;
        margin-top: .2rem;
        .sent-time {
          font-weight: 600;
          font-size: 10px;
          text-align: right;
        }
      }
      .content-right__content.is-attachment {
        background-color: transparent;
        gap: 0.4rem;
        padding: 0;
        padding-top: 5px;
        .sent-time {
          color: #0C0F4A;
        }
      }
    }
    .sender {
      justify-content: flex-end;
      color: #fff;
      .content-right__content {
        background-color: #3639af;
        flex-direction: row-reverse;
        border-radius: 8px 0 8px 8px;
        .sent-time {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .content-right__content.is-attachment {
        flex-direction: row-reverse;
      }
    }
    .receiver {
      justify-content: flex-start;
      .content-right__content {
        background-color: rgba(12, 15, 74, 0.05);
        border-radius: 8px 0 8px 8px;
        .sent-time {
          color: rgba(12, 15, 74, 0.5);
        }
      }
    }
  }
  .no-content-container {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-list {
       box-sizing: border-box;
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    position: absolute;
    height: calc(100% - 167px);
    overflow: auto;
    padding: 20px 20px 0;
    display: flex;
    scroll-behavior: smooth;
  }

  .chat-list-wrapper {
    height: 90%;
  }
  .chat-input-container {
    // height: 100px;
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .input-wrapper {
      display: grid;
      grid-template-columns: 1fr 30px;
      gap: 1rem;
      width: 100%;
      padding: 1rem;
      background-color: rgba(12, 15, 74, 0.05);
      border-radius: 8px;
      box-sizing: border-box;
      height: auto;

      .column-one {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        .el-divider {
          margin: 0;
        }
        .plus-cta {
          background-color: rgba(12, 15, 74, 0.8);
          border-radius: 100%;
          width: 20px;
          height: 20px;
          cursor:pointer;
        }

        .cta-icon {
          width: 19px;
          height: 18px;
          cursor: pointer;
        }

        :deep(.el-textarea__inner) {
          outline: none;
          border: none;
          background: transparent;
          padding: 0;
          max-height: 150px;
          // height: 31px !important;
        }
      }

      .column-two {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .cta-icon {
          cursor: pointer;
        }
      }
    }
  }
}

@include media-md-max-width {
  .chat-list-container {
    height: calc(100vh - 178px);
  }
  .chat-list-container.project-chat {
    height: calc(100vh - 216px);
  }
}
@include media-sm-max-width {
  .chat-list-container {
    height: calc(100vh - 171px);
  }
  .chat-list-container.project-chat {
    height: calc(100vh - 286px);
  }
}
@include media-xs-max-width {
  .chat-list-container.project-chat {
    height: calc(100% - 50px);
  }
  .chat-list-container {
    height: calc(100% - 55px);
  }
}
</style>
