<template>
    <div class="new-chat-container flex-column jc-sb">
        <div class="new-chat-container__row flex-column">
           <div class="new-chat-container__header flex-row ai-c">
             <el-icon class="cursor-pointer" :size="15" color="rgba(12, 15, 74, 0.5)" @click="closeNewSingleChatForm" ><ArrowLeftBold/></el-icon>
             <p class="new-chat-container__header-title default-text">Create new chat</p>
           </div>
           <p class="new-chat-container__header-description default-text">Please input the user name for the people you want to chat with </p>
           <div class="new-chat-container__content flex-column">
              <el-cascader
                  class="username-cascader"
                  v-model="selectedContact"
                  placeholder="User name"
                  :options="contacts"
                  :props="{ multiple: false }"
                  filterable
                  :show-all-levels="false"
                  @change="onSelectContact">
              </el-cascader>
            </div>
        </div>
        <div class="new-chat-container__row flex-row ai-c jc-c">
           <el-button type="primary" class="new-chat-container__button flex-row ai-c jc-c default-text"
            :class="{'active': isNextButtonActive}"
            @click="onSubmit" round :loading="submitting" :disabled="!isNextButtonActive">NEXT</el-button>
        </div>
    </div>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    ArrowLeftBold
  },
  emits: ['toggle-single-chat-form', 'on-save-user-room'],
  props: ['acceptedProjectMembers'],
  data() {
    return {
      isNextButtonActive: false,
      selectedContact: null,
      contacts: []
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters(USERS_STORE, ['getUser', 'user']),

    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
  },

  methods: {
    ...mapActions(USERS_STORE, ['getUser']),

    initialize() {
      const { acceptedProjectMembers, authenticatedUser } = this;

      if (acceptedProjectMembers && acceptedProjectMembers.length) {
        this.contacts = acceptedProjectMembers.map((member) => {
          const { id: userId, email } = member;
          return {
            value: userId,
            label: email
          };
        });

        this.contacts = this.contacts.filter((item) => item.value !== authenticatedUser.userId);
      }
    },

    onSelectContact() {
      if (this.selectedContact) {
        this.isNextButtonActive = true;
      }
    },

    onSubmit() {
      const [userId] = this.selectedContact;

      if (userId) {
        this.$emit('on-save-user-room', userId);
      }
    },

    closeNewSingleChatForm() {
      this.$emit('toggle-single-chat-form');
    }
  },
});
</script>
<style lang="scss" scoped>
.new-chat-container {
    height: 95%;

    &__header-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__header-description {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__content {
        :deep(.username-cascader) {
            width: 100%;
            background-color: transparent;
            .el-input__inner {
                background: transparent;
                border: none;
                border-bottom: 1px solid rgba(12, 15, 74, 0.2);
                outline: none;
            }
        }
        :deep(.el-cascader__search-input) {
            background: transparent;
        }
        :deep(.el-input--suffix input::placeholder) {
          color: transparent;
        }
    }
   &__button {
       width: 100%;
       border-radius: 8px;
       height: 42px;
       background: rgba(12, 15, 74, 0.05);
       color: rgba(12, 15, 74, 0.5);
       border:none;

       &:hover {
          background: rgba(12, 15, 74, 0.05);
          color: rgba(12, 15, 74, 0.5);
       }
   }
   &__button.active {
       background: #FFA500;
       color: #FFF;

       &:hover {
          background: #FFA500;
         color: #FFF;
       }
   }
}
</style>
