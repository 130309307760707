<template>
    <BoxSlotComponent title="Documents" :count="documents.length">
        <template #action>
          <ActionItemComponent
            v-if="documents.length > 3"
            :hideIcon="true"
            :label="isShowAll ? 'See less' : 'See all'"
            fontColor="#FFA500"
            fontSize="14px"
            @call-to-action="onViewAllDocuments"/>
        </template>
        <template #>
            <el-card>
                <div class="card-content">
                   <a class="item" v-for="(file, index) in toDisplayFiles" :key="index" :href="file?.url" target="_blank">
                       <div class="column-one">
                          <p class="file-type">{{getFileType(file?.originalName)}}</p>
                       </div>
                       <div class="column-two">
                          <p class="file-name">{{file?.originalName}}</p>
                       </div>
                   </a>
                   <div class="not-shown-count flex-row ai-c jc-c" v-if="notShownFiles.length && !isShowAll">
                      <p class="default-text">+{{notShownFiles.length}}</p>
                    </div>
                </div>
            </el-card>
        </template>
    </BoxSlotComponent>
</template>
<script>
import { defineComponent } from 'vue';

import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';

export default defineComponent({
  name: 'project-detail-documents-box-component',
  props: ['documents'],
  components: {
    BoxSlotComponent,
    ActionItemComponent
  },
  data() {
    return {
      numberOfShownFiles: 3,
      shownFiles: [],
      notShownFiles: [],

      toDisplayFiles: [],
      isShowAll: false,
    };
  },
  watch: {
    documents: {
      immediate: true,
      handler() {
        this.initialize();
      }
    }
  },
  methods: {
    initialize() {
      this.shownFiles = [];
      this.notShownFiles = [];
      this.toDisplayFiles = [];
      if (this.documents && this.documents.length) {
        const getShownFiles = this.documents.reduce((mappedArray, item, index) => {
          if (index < this.numberOfShownFiles) {
            mappedArray.push(item);
          }
          return mappedArray;
        }, []);
        const getNotShownFiles = this.documents.reduce((mappedArray, item, index) => {
          if (index >= this.numberOfShownFiles) {
            mappedArray.push(item);
          }
          return mappedArray;
        }, []);

        this.shownFiles = getShownFiles;
        this.notShownFiles = getNotShownFiles;
        this.toDisplayFiles = getShownFiles;
      }
    },

    getFileType(file) {
      return file.slice(file.indexOf('.') + 1).toUpperCase();
    },

    onViewAllDocuments() {
      if (this.toDisplayFiles.length === this.shownFiles.length) {
        this.toDisplayFiles = this.documents;
        this.isShowAll = true;
      } else {
        this.toDisplayFiles = this.shownFiles;
        this.isShowAll = false;
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.el-card {
    background: transparent;
    outline:none;
    border:none;
    box-shadow: none;
    :deep(.el-card__body) {
        padding:0;
    }
    .card-content {
        display:flex;
        flex-direction: column;
        gap:1rem;

        .item {
            display:grid;
            grid-template-columns: 1fr 4fr;
            border-radius: 8px;
            background-color:rgba(12, 15, 74, 0.05);
            text-decoration: none;

            .column-one {
                display:flex;
                align-items: center;
                justify-content:center;
                background-color:rgba(12, 15, 74, 0.06);
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;

                .file-type {
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 14px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #0C0F4A;
                    text-transform: uppercase;
                }
            }
            .column-two {
                display:flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 1rem;
                box-sizing: border-box;

                .file-name {
                    font-family: Mulish;
                    font-size: 10px;
                    font-weight: 600;
                    line-height: 12px;
                    letter-spacing: 0.5px;
                    text-align: left;
                    color: #0C0F4A;
                }
            }
        }
        .not-shown-count {
          background: rgba(12, 15, 74, 0.1);
          border-radius: 8px;
          box-sizing: border-box;
          width: 50px;
          height: 50px;

          p {
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.25px;
          }
        }
    }
}
</style>
