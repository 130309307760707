// import { mapGetters } from 'vuex';
import ContactItemComponent from './ContactItemComponent.vue';
// import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
export default {
    name: 'all-contact-component',
    components: {
        ContactItemComponent
    },
    props: ['userMessageRooms', 'selectedUserMessageRoom', 'isProjectChat'],
    emits: ['select-user-message-room'],
    created() {
    },
    computed: {
    // ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    // member() {
    //   let member = this.item.fromUser && this.item.fromUser.id !== this.authenticatedUser?.userId
    //     ? this.item.fromUser : this.item.toUser;
    //   if (!member) {
    //     member = this.item?.project?.projectCreator;
    //   }
    //   return member;
    // },
    // memberIsOnline() {
    //   if (this.item?.members?.length) {
    //     // eslint-disable-next-line radix
    //     return this.item.members.find((m) => parseInt(m.id) === this.member.id);
    //     // return this.item.id === this.member.id;
    //   }
    //   return false;
    // },
    },
    methods: {
        selectUserMessageRoom(userMessageRoom) {
            this.$emit('select-user-message-room', userMessageRoom);
        },
    }
};
