import { defineComponent } from 'vue';
import AllContactComponent from './AllContactComponent.vue';
// import PinnedContactComponent from './PinnedContactComponent.vue';
export default defineComponent({
    name: 'message-contact-list-component',
    components: {
        AllContactComponent
        // PinnedContactComponent
    },
    props: ['userMessageRooms', 'selectedUserMessageRoom', 'isProjectChat'],
    emits: ['select-user-message-room'],
    created() {
    },
    methods: {
        selectUserMessageRoom(userMessageRoom) {
            this.$emit('select-user-message-room', userMessageRoom);
        },
    }
});
