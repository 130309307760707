<template>
  <div class="message-dashboard-list-container" :class="{'project-chat-class': routeName === 'chat'}">
    <MessageListHeader
      :isProjectChat="isProjectChat"
      @set-view-contact="backToContacts"
      :selectedUserMessageRoom="selectedUserMessageRoom"
      :members="acceptedProjectMembers"
      />
    <ChatListComponent
      :selectedMessageRoom="selectedUserMessageRoom"
      @save-message="saveNewMessage"
      @submit-attachment="saveAttachment"
      @set-unread-list="setUnreadMessageList"
      @trigger-is-typing-event="triggerIsTypingEvent($event)" />
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';

import ChatListComponent from '@/core/components/messages/ChatListComponent.vue';
import MessageListHeader from '@/core/components/messages/MessageListHeader.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { MESSAGES_STORE } from '@/store/modules/messages';

export default defineComponent({
  name: 'message-dashboard-list',
  components: {
    ChatListComponent,
    MessageListHeader
  },
  props: ['selectedUserMessageRoom', 'selectedProject', 'isProjectChat', 'acceptedProjectMembers'],
  emits: ['save-message', 'set-unread-list', 'set-view-contact', 'trigger-is-typing-event'],
  setup() {
    const routeName = computed(() => {
      return useRouter().currentRoute.value.name;
    });

    return {
      routeName,
    };
  },

  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
  },

  methods: {
    ...mapActions(MESSAGES_STORE, [
      'createUserMessageRoomAttachment'
    ]),

    saveNewMessage(payload) {
      this.$emit('save-message', payload);
    },

    saveAttachment(file, cb) {
      const { userId } = this.authenticatedUser;
      const userMessageRoomId = this.selectedUserMessageRoom?.id;

      this.createUserMessageRoomAttachment({ userId, userMessageRoomId, file })
        .then((response) => {
          if (response) {
            // (this as any).selectedUserMessageRoom.userMessageAttachments.push(response);
            cb(response);
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: 'Error Uploading File Attachment',
            message: e ? e?.response?.message : 'File attachment has not been successfully sent. Please try again.'
          });
        });
    },

    setUnreadMessageList() {
      this.$emit('set-unread-list');
    },

    backToContacts() {
      this.$emit('set-view-contact');
    },

    triggerIsTypingEvent(event) {
      this.$emit('trigger-is-typing-event', event);
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.message-dashboard-list-container {
  // height: 100%;
  border-radius: 40px;
  height: calc(100% - 70px);
}

@include media-md-max-width() {
  .message-dashboard-list-container {
    // height: 100%
  }
}
@include media-sm-max-width() {
  .message-dashboard-list-container.project-chat-class {
    height: 100%
  }
  .message-dashboard-list-container {
    height: calc(100% - 2px);
  }
}

@include media-xs-max-width() {
  .message-dashboard-list-container.project-chat-class {
    height: calc(100% - 2px);
  }
}
</style>
