<template>
    <BoxSlotComponent title="Members" :count="members && members.length">
        <template #action>
            <ActionItemComponent
            v-if="isGroupRoom && members && members.length > 3"
            :hideIcon="true"
            label="See all"
            fontColor="#FFA500"
            fontSize="14px"
            @call-to-action="viewAllMembers"/>
        </template>
        <template #>
            <el-card>
                <div class="card-content">
                   <div class="item" v-for="(member, index) in members" :key="index">
                       <div class="column-one">
                            <UserThumbnail
                                borderRadius="50px"
                                width="25px"
                                height="25px"
                                style="display:flex;justify-content:center;align-items:center;"
                                :key="member?.userPublicProfile?.profileImage"
                                :source="member?.userPublicProfile?.profileImage"
                                >
                            </UserThumbnail>
                            <p class="name">{{ member.firstName }} {{ member.lastName }}</p>
                       </div>
                       <!-- <div class="column-two">
                          <el-icon class="action-icon" :size="15" color="#0C0F4A"><PhoneFilled/></el-icon>
                          <inline-svg class="action-icon" :src="require(`@/assets/icons/chat-icon.svg`)"></inline-svg>
                       </div> -->
                   </div>
                </div>
            </el-card>
        </template>
    </BoxSlotComponent>
</template>
<script>
// import { PhoneFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

import BoxSlotComponent from '@/core/components/common/box-cards/BoxSlotComponent.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

export default defineComponent({
  name: 'project-detail-members-box-component',
  components: {
    BoxSlotComponent,
    UserThumbnail,
    ActionItemComponent
    // PhoneFilled
  },

  props: ['members', 'isGroupRoom'],

  created() {
  }
});
</script>
<style lang="scss" scoped>
.el-card {
    background: transparent;
    outline:none;
    border:none;
    box-shadow: none;
    :deep(.el-card__body) {
        padding:0;
    }
    .card-content {
        display:flex;
        flex-direction: column;
        gap:1rem;

        .item {
            display:flex;
            justify-content: space-between;
            align-items:center;

            .column-one {
                display:flex;
                align-items: center;
                gap: 1rem;

                .name {
                    margin: 0;
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                }
            }
            .column-two {
                display:flex;
                align-items: center;
                gap: 2rem;
            }
        }
    }
}
</style>
