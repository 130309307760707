<template>
    <div class="new-chat-container flex-column jc-sb">
        <div class="new-chat-container__row flex-column">
           <div class="new-chat-container__header flex-row ai-c">
             <el-icon class="cursor-pointer" :size="15" color="rgba(12, 15, 74, 0.5)" @click="closeNewGroupChatForm" ><ArrowLeftBold/></el-icon>
             <p class="new-chat-container__header-title default-text">Create new group chat</p>
           </div>
           <p class="new-chat-container__header-description default-text" v-if="currentScreenType === MEMBER_FORM">Please input the user name for the people you want to chat with </p>
           <div class="new-chat-container__content flex-column" v-if="currentScreenType === MEMBER_FORM">
              <el-cascader
                  class="username-cascader"
                  v-model="selectedContact"
                  placeholder="User name"
                  :options="contacts"
                  :props="{ multiple: false }"
                  filterable
                  :show-all-levels="false"
                  @change="onSelectContact">
              </el-cascader>
            </div>
            <!-- LIST MEMBER -->
            <div class="new-chat-container__member-list flex-column" v-if="tempAddedContacts.length && currentScreenType === MEMBER_FORM">
              <div class="new-chat-container__member-item flex-row ai-c jc-sb" v-for="(contact, index) in tempAddedContacts" :key="index">
                <p class="new-chat-container__member-name default-text">{{ contact.label }}</p>
                <el-icon :size="20" color="rgba(12, 15, 74, 0.5)" @click="onRemove(contact.value)"><RemoveFilled/></el-icon>
              </div>
            </div>
        </div>
        <!-- UPLOAD AND GROUP NAME FORM -->
          <div class="new-chat-container__upload-form flex-column ai-c jc-c" v-if="currentScreenType === UPLOAD_FORM">
            <!-- <el-upload
              class="new-chat-container__upload flex-row ai-c jc-c"
              :show-file-list="false"
              action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
            >
              <inline-svg
              class="profile-photo-empty"
              :src="require(`@/assets/icons/upload-group-icon.svg`)"></inline-svg>
            </el-upload> -->

            <el-image
              class="profile-photo"
              :src="groupAvatarRealSrc"
              v-if="groupAvatarRealSrc"
              :fit="'cover'">
            </el-image>

            <UploadForm
              :uploadContainerClass="'group-avatar-upload-form new-chat-container__upload'"
              :record="form"
              :useCustomUploadIcon="false"
              :userEmptyAvatarIcon="true"
              :showFileList="false"
              @on-progress-upload="onProgressUpload"
              @on-upload-success="onUploadSuccess"
            />

            <el-input type="text" v-model="groupName" placeholder="Group name"/>
          </div>

        <!-- BUTTON CONTAINER -->
        <p><i><small>Note: A group needs at least 2 members.</small></i></p>
        <div class="new-chat-container__row flex-row ai-c jc-c">
           <el-button type="primary" class="new-chat-container__button flex-row ai-c jc-c default-text"
            :class="{'active': isNextButtonActive}"
            @click="onNext" round :loading="submitting" :disabled="!isNextButtonActive" v-if="currentScreenType === MEMBER_FORM">NEXT</el-button>
            <el-button type="primary" class="new-chat-container__button flex-row ai-c jc-c default-text"
            :class="{'active': isCreateButtonActive}"
            @click="onSubmit" round :loading="submitting" :disabled="!isCreateButtonActive" v-else>{{ uploadingProfileImage ? 'Uploading...': 'Create' }}</el-button>
        </div>
    </div>
</template>
<script>
import { ArrowLeftBold, RemoveFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_MESSAGE_GROUP_CACHE } from '@/store/modules/attachment-cache/constants';

const MEMBER_FORM = 'member_form';
const UPLOAD_FORM = 'upload_form';

export default defineComponent({
  components: {
    ArrowLeftBold,
    RemoveFilled,
    UploadForm
  },
  emits: ['toggle-group-chat-form', 'on-save-group-user-room'],
  props: ['acceptedProjectMembers'],

  data() {
    return {
      isNextButtonActive: false,
      isCreateButtonActive: false,
      selectedContact: null,
      contacts: [],

      tempAddedContacts: [],

      screenTypes: {
        memberForm: MEMBER_FORM,
        uploadForm: UPLOAD_FORM
      },

      MEMBER_FORM,
      UPLOAD_FORM,

      currentScreenType: MEMBER_FORM,

      groupName: '',

      form: { attachments: [] },

      groupAvatarTmpSrc: '',
      groupAvatarRealSrc: '',

      uploadingProfileImage: false
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    groupName: {
      handler() {
        this.groupNameInputChecker();
      }
    },

    groupAvatarTmpSrc: {
      immediate: true,
      handler(value) {
        if (value) {
          this.loadProfileImageUrl(value);
        }
      }
    }
  },
  methods: {
    ...mapActions(ATTACHMENT_CACHE_STORE, [
      'getAttachments',
      'resetCacheEntity'
    ]),

    initialize() {
      const { acceptedProjectMembers } = this;

      if (acceptedProjectMembers && acceptedProjectMembers.length) {
        this.contacts = acceptedProjectMembers.map((member) => {
          const { id, email } = member;
          return {
            value: id,
            label: email
          };
        });
      }
    },

    groupNameInputChecker() {
      if (this.groupName !== '' && this.groupName && this.groupAvatarRealSrc) {
        this.isCreateButtonActive = true;
      } else {
        this.isCreateButtonActive = false;
      }
    },

    onSelectContact() {
      const contact = this.selectedContact;
      const [userId] = contact;
      const contactItem = this.contacts.find((item) => item.value === userId);
      const contactExist = this.tempAddedContacts.find((item) => item.value === userId);

      if (!contactExist) {
        this.tempAddedContacts.push(contactItem);
      }

      if (this.tempAddedContacts.length > 1) {
        this.isNextButtonActive = true;
      }
    },

    onRemove(value) {
      this.tempAddedContacts = this.tempAddedContacts.filter((item) => item.value !== value);

      if (this.tempAddedContacts.length < 2) {
        this.isNextButtonActive = false;
      }
    },

    onSubmit() {
      const { tempAddedContacts } = this;
      const values = tempAddedContacts.map((item) => {
        const { value, label } = item;

        return {
          groupName: this.groupName,
          userId: value,
          email: label,
          groupAvatar: this.groupAvatarTmpSrc
        };
      });

      if (values.length > 1) {
        this.$emit('on-save-group-user-room', values);
      }
    },

    onNext() {
      this.currentScreenType = this.UPLOAD_FORM;
    },

    onUploadSuccess() {
      this.isCreateButtonActive = false;

      const { attachments } = this.form;
      const [firstItem] = attachments;

      // groupAvatarImage
      this.groupAvatarTmpSrc = firstItem.attachment;
      this.form = { attachments: [] };
    },

    onProgressUpload() {
      this.uploadingProfileImage = true;
    },

    loadProfileImageUrl(value) {
      this.uploadingProfileImage = true;
      this.getAttachments({
        name: USER_MESSAGE_GROUP_CACHE,
        attachments: [{ attachment: value }],
        returnFirstOnly: true,
      })
        .then(async ({ src }) => {
          this.groupAvatarRealSrc = src;
          this.uploadingProfileImage = false;

          this.groupNameInputChecker();
        })
        .catch(() => {})
        .finally(() => {
          this.uploadingProfileImage = false;
        });
    },

    closeNewGroupChatForm() {
      this.$emit('toggle-group-chat-form');
    }
  },
});
</script>
<style lang="scss" scoped>

.new-chat-container {
    height: 95%;

    &__header-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__header-description {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__content {
        :deep(.username-cascader) {
            width: 100%;
            background-color: transparent;
            .el-input__inner {
                background: transparent;
                border: none;
                border-bottom: 1px solid rgba(12, 15, 74, 0.2);
                outline: none;
            }
        }
        :deep(.el-cascader__search-input) {
            background: transparent;
        }
        :deep(.el-input--suffix input::placeholder) {
          color: transparent;
        }
    }
    &__member-list {
      max-height: 400px;
      min-height: 250px;
      overflow-y: scroll;
    }
    &__member-item {
      width: 100%;
    }
    &__upload-form {
      gap: 2rem;
      margin-top: 2.5rem;
      .el-input {
        border: none;
        outline: none;
        background: transparent;
        border-bottom: 2px solid rgba(12, 15, 74, 0.2);
        :deep(.el-input__inner) {
          background: transparent;
          padding-left: 0;
          border:none;
        }
      }
    }
    &__upload {
      width: 150px;
      height: 150px;
      background: rgba(12, 15, 74, 0.15);
      border-radius: 100%;
      box-sizing: border-box;
    }
   &__button {
       width: 100%;
       border-radius: 8px;
       height: 42px;
       background: rgba(12, 15, 74, 0.05);
       color: rgba(12, 15, 74, 0.5);
       border:none;

       &:hover {
          background: rgba(12, 15, 74, 0.05);
          color: rgba(12, 15, 74, 0.5);
       }
   }
   &__button.active {
       background: #FFA500;
       color: #FFF;

       &:hover {
          background: #FFA500;
         color: #FFF;
       }
   }
}
</style>
