import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
import CreateMicroTaskModal from '@/core/components/messages/modal/CreateMicroTaskModal.vue';
import { MESSAGES_STORE } from '@/store/modules/messages';
export default {
    name: 'message-layout-container-slot',
    components: {
        CreateMicroTaskModal
    },
    props: {
        messagesToggleOpenCloseDetailClass: String,
        hasNoContact: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const routeName = computed(() => {
            return useRouter().currentRoute.value.name;
        });
        return {
            routeName,
        };
    },
    computed: {
        ...mapGetters(MESSAGES_STORE, ['getOpenCreateMicroTaskModal']),
    },
    methods: {
        ...mapActions(MESSAGES_STORE, ['setOpenCreateMicroTaskModal']),
    }
};
